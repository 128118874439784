<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { appBarStore } from '@/store/AppBarStore.js'
import { formatMonthString } from '@/utils/dateFormatter'
import router from '@/router';

const store = useStore()

const isLoading = ref(false)

const subscription = computed(() => store.state.subscription.subscription)
const error = computed(() => store.state.subscription.error)
const currentPeriodEndAt = computed(() => {
  if (subscription.value) {
    return formatMonthString(subscription.value.currentPeriodEndAt)
  } else {
    return "..."
  }
})

const handleCancelSubscription = async () => {
  isLoading.value = true
  store.dispatch('subscription/cancelSubscription')
    .catch((error) => console.error(error))
    .finally(() => isLoading.value = false)
}

const loadSubscription = async () => {
  store.dispatch('subscription/fetchSubscription')
    .then(() => checkSubscriptionStatus())
    .catch((error) => console.error(error))
}

const checkSubscriptionStatus = () => {
  if (subscription.value && subscription.value.status !== 'active') {
    router.push({ name: 'UserSubscription' })
  }
}

onMounted(() => {
  if (!subscription.value) {
    loadSubscription()
  } else {
    checkSubscriptionStatus()
  }
})

appBarStore.value.setTitle('Subscription')

</script>

<template>
  <v-card
    max-width="800"
    class="mx-auto mt-4 text-start card-primary"
  >
    <v-card-title class="d-flex">
      <h2 class="text-h5">Cancel Subscription</h2>
    </v-card-title>

    <v-card-text>

      <div>
        <v-btn
          class="no-padding"
          variant="plain"
          :to="{ name: 'UserSubscription' }"
        >&lt; Back to  Subscription</v-btn>
      </div>

      <v-card
        color="surface-light"
        class="mx-auto my-2 pa-6 text-center text-body-1"
      >
        <p class="text-h6">Cancel your subscription</p>
        <p class="text-body-1">
          Cancellation will take effect on 
          <span class="font-weight-bold">
            {{ currentPeriodEndAt }}
          </span>.
        </p>
        <p>You'll still be able to play until then.</p>

        <div v-if="error" class="my-8">
          <p class="text-error">{{ error }}</p>
        </div>

        <div class="mb-4">
          <v-btn
            class="mt-8 me-1"
            variant="outlined"
            color="primary"
            flat
            block
            @click="handleCancelSubscription"
          > Finish Cancellation </v-btn>
        </div>
      </v-card>

      <v-card
        color="surface-light"
        class="mx-auto my-2 pa-6 text-center text-body-1"
      >
        <p class="text-h6">Want to stick around?</p>
        <p>Your subscription will remain the same.</p>
        <div class="mb-4">
          <v-btn
            class="mt-8 me-1"
            variant="tonal"
            rounded="xl"
            size="large"
            flat
            :to="{ name: 'Adventure' }"
          > Return to Playing </v-btn>
        </div>
      </v-card>
    
    </v-card-text> 
  </v-card>
</template>

<style scoped>
.no-padding {
  padding: 0;
}
</style>
