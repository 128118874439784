<script setup>
import { watch } from 'vue'

const props = defineProps({
  clientSecret: {
    type: String,
    required: true
  },
});

const isCheckoutTriggered = defineModel('isCheckoutTriggered')
const isCheckoutSuccessful = defineModel('isCheckoutSuccessful')
isCheckoutSuccessful.value = false

let stripe, elements, paymentElement
function initializeStripe() {
  const options = {
    clientSecret: props.clientSecret,
  };
  stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
  elements = stripe.elements(options)
  paymentElement = elements.create('payment')
}

function displayError(event) {
  const displayError = document.getElementById('payment-error-message')
  if (event.error) {
    displayError.textContent = event.error.message
  } else {
    displayError.textContent = ''
  }
}

async function handleCheckoutEvent() {
  const response = await stripe.confirmSetup({
    elements,
    confirmParams: {
      // return_url: "https://example.com/order/123/complete",
    },
    redirect: 'if_required'
  });

  if (response.error) {
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Show error to your customer (for example, payment
    // details incomplete)
    const messageContainer = document.querySelector('#payment-error-message');
    messageContainer.textContent = response.error.message;
    isCheckoutSuccessful.value = false;
  } else {
    // Your customer will be redirected to your `return_url`. For some payment
    // methods like iDEAL, your customer will be redirected to an intermediate
    // site first to authorize the payment, then redirected to the `return_url`.
    isCheckoutSuccessful.value = true;
  }
  isCheckoutTriggered.value = false;
}

watch(() => props.clientSecret, (newVal) => {
  if (newVal) {
    initializeStripe()
    paymentElement.mount('#payment-element')
    paymentElement.on('change', (event) => {
      displayError(event)
    })
  }
})

watch(() => isCheckoutTriggered.value, (newVal) => {
  if (newVal) {
    handleCheckoutEvent()
  }
})

// TODO: stop watches on unmount
</script>

<template>
  <v-sheet class="mx-auto mt-4">
    
    <!-- Payment elements inserted by Stripe SDK here -->
    <div id="payment-element"></div>
    <div id="payment-error-message" role="alert"></div>

    <div id="terms" class="text-left text-body-2 mt-4">
      <!-- {{ terms }} -->
    </div>
  </v-sheet>
</template>