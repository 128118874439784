export function formatCurrency(value, currency = 'USD') {
  const numberFormat = new Intl.NumberFormat(
    'en-US',
    { 
      style: 'currency',
      currency: currency
    }
  )
  return numberFormat.format(value || 0)
}

export function formatSubunitToCurrency(value, currency = 'USD') {
  // TODO: implement support of zero decimal currencies with lookup
  return formatCurrency((value || 0) / 100, currency)
}