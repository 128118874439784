<script setup>
const props = defineProps({
  errorMessage: {
    type: String,
    default: ''
  },
  isDisabled: {
    type: Boolean,
    default: false
  }
});
const email = defineModel('email')
const password = defineModel('password')
</script>

<template>
  <v-sheet min-height="350">
    <div class="mt-3 mb-6">
      Create a password to start your adventure
    </div>
    <v-sheet width="300" class="mx-auto mt-4">
      <v-text-field
        label="Email"
        v-model="email"
        :disabled="props.isDisabled"
        :error-messages="props.errorMessage"
      ></v-text-field>
      <v-text-field
        label="Password"
        type="password"
        v-model="password"
        :disabled="props.isDisabled"
        :error-messages="props.errorMessage"
      ></v-text-field>
    </v-sheet>
  </v-sheet>
</template>