import { api } from '@/utils/api.js'

export default function useBillingSubscriptionCreate() {
  const createSubscription = async (priceId) => {
    return api.billing.subscription.create(priceId)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.error(error)
        switch (error.response.status) {
          case 401:
            error.displayMessage = "Session has expired"
            break
          default:
            error.displayMessage = "Unable to create subscription"
        }
        throw error
      })
  }

  return {
    createSubscription
  }
}

export async function requestSubscriptionCreate(
  priceId,
  options = {},
) {
  return api.billing.subscription.create(priceId, options)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.error(error)
      switch (error.response.status) {
        case 401:
          error.displayMessage = "Session has expired"
          break
        default:
          error.displayMessage = "Unable to create subscription"
      }
      throw error
    })
}
