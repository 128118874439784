// NOTE!!! Create subscription manages creating a customer server side
// Server side creation allows for better management of any failures
// No obvious reason to use this independant create customer at this point
import { api } from '@/utils/api.js'

export default function useBillingCustomerCreate() {
  const createCustomer = async (email) => {
    return api.billing.customer.create(email)
      .then((response) => {
        const customerId = response.data.customerId
        return customerId
      })
      .catch((error) => {
        console.error(error)
        switch (error.response.status) {
          case 401:
            error.displayMessage = "Session has expired"
            break
          default:
            error.displayMessage = "Unable to create customer id"
        }
        throw error
      })
  }

  return {
    createCustomer
  }
}

export async function requestCustomerUpdate(
  options = {},
) {
  return api.billing.customer.update(
    options
  )
    .then((response) => {
      const customerId = response.data.customerId
      return customerId
    })
    .catch((error) => {
      console.error(error)
      switch (error.response.status) {
        case 401:
          error.displayMessage = "Session has expired"
          break
        case 422:
          error.displayMessage = "Unable to process address. Double check your entry and try again"
          break
        default:
          error.displayMessage = "Unable to update customer"
      }
      throw error
    })
}