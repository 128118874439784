<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-card class="mx-auto mt-4 card-primary">
    <h1 class="mt-4 mb-6">Login to Your Account</h1>
    <v-sheet width="300" class="mx-auto">
      <v-form @submit.prevent="signIn">
        <v-text-field
          v-model="email"
          label="Email"
        ></v-text-field>
        <v-text-field
          type="password"
          v-model="password"
          label="Password"
        ></v-text-field>
        <v-btn
          class="me-4"
          type="submit"
          color="primary"
          rounded="xl"
          size="large"
          flat
          :loading="isLoading"
        > Submit </v-btn>
        <v-btn
          class="mt-8 me-4"
          variant="text"
          prepend-icon="mdi-lock-question"
          :to="{ name: 'ResetPassword' }"
        > Forgot Password </v-btn>
      </v-form>
    </v-sheet>
  </v-card>

  <v-dialog id="errorDialog"
    v-model="isError"
    width="300"
  >
    <v-card>
      <v-card-text>
        {{ errorMessage }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="isError = false">Dismiss</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref } from 'vue'
import { getAuth, signInWithEmailAndPassword, getIdTokenResult } from 'firebase/auth'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router' // import router
import { appBarStore } from '@/store/AppBarStore.js'

const isLoading = ref(false);
const isError = ref(false);
const email = ref('')
const password = ref('')
const errorMessage = ref('')

const store = useStore()
const router = useRouter() // get a reference to our vue router

appBarStore.value.update(
  'Login',
  [
    {
      label: 'Register',
      icon: 'mdi-list-box-outline',
      routeName: 'Register'
    }
  ]
)

const signIn = () => { // we also renamed this method 
  isLoading.value = true;
  signInWithEmailAndPassword(getAuth(),email.value, password.value) // THIS LINE CHANGED
    .then((data) => {
      store.dispatch('setUser', data.user.uid)
      // NOTE: setting the token in store here, however, onIdTokenChanged in main.js is also setting
      // the store on any token changes, including login
      store.dispatch('setToken', data.user.accessToken)
      return getIdTokenResult(data.user, true) // true forces refres
    })
    .then((idTokenResult) => {
      const isRegistrationComplete = idTokenResult.claims.is_registration_complete || false;
      store.dispatch('setIsRegistrationComplete', isRegistrationComplete)
      if (isRegistrationComplete) {
        router.push('/adventure')
      } else {
        router.push('/register')
      }
    })
    .catch(error => {
      switch (error.code) {
        case 'auth/invalid-email':
          errorMessage.value = 'Invalid email'
          break
        case 'auth/user-not-found':
          errorMessage.value = 'No account with that email was found'
          break
        case 'auth/wrong-password':
          errorMessage.value = 'Incorrect password'
          break  
        default:
          errorMessage.value = 'Email or password was incorrect'
          break
      }
      isError.value = true;
    })
    .finally(() => {
      isLoading.value = false;
    });
}
</script>

<style scoped>
#errorDialog {
  text-align: center;
}
</style>