<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { appBarStore } from '@/store/AppBarStore.js'
import { useBillingSessionCreate } from '@/composables/useBillingSession'
import UserSubscriptionPaymentDetails from '@/components/UserSubscriptionPaymentDetails.vue';
import UserPaymentUpdateCheckout from '@/components/UserPaymentUpdateCheckout.vue';

const isLoading = ref(false)
const errorMessage = ref('')
const clientSecret = ref(null)
const isCheckoutTriggered = ref(false)
const isCheckoutSuccessful = ref(false)

appBarStore.value.setTitle('Update Payment')

const { createSession } = useBillingSessionCreate()
const createCheckoutSession = async () => {
  isLoading.value = true
  createSession()
    .then((session) => {
      console.log(session)
      clientSecret.value = session.clientSecret
    })
    .catch((error) => errorMessage.value = error.displayMessage)
    .finally(() => isLoading.value = false)
}

const handleUpdatePaymentMethod = async () => {
  isLoading.value = true
  isCheckoutTriggered.value = true
  // TODO: trigger a "handleCheckout" function in the RegisterCheckout component
}

const stopWatch = watch(isCheckoutTriggered, (newVal) => {
  if (!newVal) {
    isLoading.value = false
    if (isCheckoutSuccessful.value) {
      // router.push('/register/complete')
      console.log("update success")
    }
  }
})

onMounted(() => {
  createCheckoutSession()
})

onUnmounted(() => {
  stopWatch()
})

</script>

<template>
  <v-card
    max-width="800"
    class="mx-auto mt-4 text-start card-primary"
  >
    <v-card-title class="d-flex">
      <h2 class="text-h5">Update Payment Method</h2>
    </v-card-title>

    <v-card-text>
      <p class="text-subtitle-1">Payment Info</p>
      <UserSubscriptionPaymentDetails />

      <p class="text-subtitle-1">Update Payment Info</p>
      <UserPaymentUpdateCheckout
        v-model:isCheckoutTriggered="isCheckoutTriggered"
        v-model:isCheckoutSuccessful="isCheckoutSuccessful"
        :clientSecret="clientSecret"
        />

      <v-btn
        class="me-4"
        type="submit"
        color="primary"
        rounded="xl"
        size="large"
        flat
        :loading="isLoading"
        @click="handleUpdatePaymentMethod"
      > Update Payment Method </v-btn>

    </v-card-text> 
  </v-card>
</template>
