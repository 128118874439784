<script setup>
import { ref, onMounted } from 'vue'
import { getAuth, sendEmailVerification } from "firebase/auth"

const isVerified = ref(false)
const isEmailng = ref(false)
const isError = ref(false)
const email = ref(null)
const isResendDisabled = ref(false)
const resendTimer = ref(0)

const startResendTimer = () => {
  resendTimer.value = 60
  isResendDisabled.value = true
  const interval = setInterval(() => {
    if (resendTimer.value > 0) {
      resendTimer.value -= 1
    } else {
      clearInterval(interval)
      isResendDisabled.value = false
    }
  }, 1000)
}

const handleSendEmailVerification = async () => {
  const auth = getAuth()
  email.value = auth.currentUser.email
  if (auth.currentUser.emailVerified) {
    isVerified.value = true
    return
  }

  isEmailng.value = true
  isError.value = false
  const returnUrl = window.location.href;
  const actionCodeSettings = {
    url: returnUrl
  }
  sendEmailVerification(auth.currentUser, actionCodeSettings)
    .then(() => {
      startResendTimer()
    })
    .catch((error) => {
      isError.value = true
      console.error(error)
    })
    .finally(() => isEmailng.value = false)
}

onMounted(() => {
  handleSendEmailVerification()
})

</script>

<template>
  <v-sheet min-height="350">
    <v-sheet v-if="isVerified" class="mx-auto mt-4">
      <p class="text-h4">Email address verified</p>
      <p class="my-6">Your email has been successfully verified for <span class="font-weight-bold">{{ email }}</span>.</p>
    </v-sheet>

    <v-sheet v-else class="mx-auto mt-4">
      <p class="text-h4">Verify your email address</p>

      <div v-if="isError">
        <p class="my-6 text-error">Failed to send verification link. Please try again later.</p>
      </div>
      <div v-else-if="isEmailng">
        <p class="my-6">Sending a verification link to <span class="font-weight-bold">{{ email }}</span>.</p>
      </div>
      <div v-else>
        <p class="my-6">We have sent a verification link to <span class="font-weight-bold">{{ email }}</span>.</p>
        <p>Click on the link to complete the verification process.</p>
        <p>You might need to <span class="font-weight-bold">check your spam folder</span>.</p>
      </div>

      <div class="my-6">
        <v-btn
          variant="plain"
          :loading="isEmailng"
          :disabled="isResendDisabled"
          @click="handleSendEmailVerification"
        >Resend Email</v-btn>
        <p 
          v-if="isResendDisabled"
          class="text-caption"
        >
          You can resend the email in {{ resendTimer }} seconds
        </p>
      </div>
    </v-sheet>
  </v-sheet>
</template>