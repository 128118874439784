<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { appBarStore } from '@/store/AppBarStore.js'
import ProgressSheet from '@/components/ProgressSheet.vue'
import RegisterPlan from '@/components/RegisterPlan.vue'
import FormSubscriptionCheckout from '@/components/FormSubscriptionCheckout.vue'

const RenewSteps = {
  PLAN: "1",
  CHECKOUT: "2"
}

const isLoading = ref(false)
const isError = ref(false)
const priceId = ref('')
const selectedPlan = ref(null)
const errorMessage = ref('')
const checkoutComponent = ref(null)
const activeStep = ref(RenewSteps.PLAN)

const router = useRouter()

const previousStep = () => {
  let step = Number(activeStep.value)
  if (step > 1) {
    step--
  }
  activeStep.value = step.toString()
  errorMessage.value = ''
}

const stepperActionsDisabled = computed(() => {
  if (isLoading.value) {
    return true
  }

  let step = Number(activeStep.value)
  switch(step) {
    case 1: return 'prev'
    default: return false
  }
})
const checkoutLabel = 'Subscribe'
const nextButtonText = computed(() => {
  let step = Number(activeStep.value)
  switch(step) {
    case Number(RenewSteps.CHECKOUT): return checkoutLabel
    default: return 'Next'
  }
})
const nextStep = () => {
  errorMessage.value = ''
  let step = Number(activeStep.value)
  switch (step) {
    case Number(RenewSteps.CHECKOUT):
      handleCheckout()
      break
    default:
      step++
      activeStep.value = step.toString()
  }
}
const isStepComplete = (step) => {
  return Number(activeStep.value) > Number(step)
}

const handleCheckout = async () => {
  isLoading.value = true
  await checkoutComponent.value.submit()
    .then(() => router.push({name: "UserSubscriptionActive"}))
    .catch((error) => console.error(error))
  isLoading.value = false
}

appBarStore.value.setTitle('Subscription')
</script>

<template>
  <!-- <v-card class="mx-auto mt-4 card-primary">
    <h1 class="mt-4 mb-6">Restart Subscription</h1> -->

    <v-stepper
      class="mx-auto mt-4 view-primary d-flex flex-column"
      v-model="activeStep"
    >
      <v-stepper-header class="px-10">
        <v-stepper-item
          title="Plan"
          color="tertiary"
          :value="RenewSteps.PLAN"
          :complete="isStepComplete(RenewSteps.PLAN)"
        ></v-stepper-item>

        <v-divider></v-divider>

        <v-stepper-item
          title="Checkout"
          color="tertiary" 
          :value="RenewSteps.CHECKOUT"
          :complete="isStepComplete(RenewSteps.CHECKOUT)"
        ></v-stepper-item>
      </v-stepper-header>

      <v-stepper-window>
        <v-stepper-window-item :value="RenewSteps.PLAN">
          <ProgressSheet :isLoading="isLoading">
            <RegisterPlan
              v-model:priceId="priceId"
              v-model:selectedPlan="selectedPlan"
            />
          </ProgressSheet>
        </v-stepper-window-item>

        <v-stepper-window-item :value="RenewSteps.CHECKOUT">
          <ProgressSheet :isLoading="isLoading">
            <FormSubscriptionCheckout
              ref="checkoutComponent"
              :priceId="priceId"
              :plan="selectedPlan"
              :checkoutLabel="checkoutLabel"
            />
          </ProgressSheet>
        </v-stepper-window-item>
      </v-stepper-window>

      <v-spacer></v-spacer>
      <v-stepper-actions
        @click:prev="previousStep"
        @click:next="nextStep"
        :disabled=stepperActionsDisabled
        :next-text="nextButtonText"
      ></v-stepper-actions>

    </v-stepper>
  <!-- </v-card> -->

  <v-dialog id="errorDialog"
    v-model="isError"
    width="300"
  >
    <v-card>
      <v-card-text>
        {{ errorMessage }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="isError = false">Dismiss</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
#errorDialog {
  text-align: center;
}
</style>