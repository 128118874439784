<script setup>
import { ref, computed, onMounted } from 'vue'
import { requestEstimateCreate } from '@/composables/useBillingEstimate.js'
import ProgressOverlay from './ProgressOverlay.vue';

const props = defineProps({
  selectedPriceId: {
    type: String,
    required: true
  },
  promoCode: {
    type: String,
    default: undefined
  },
});

const plan = defineModel('plan')
const isLoading = ref(false)
const calculatedAmount = ref(plan.value.amount)

const cardColor = computed(() => {
  return plan.value.priceId === props.selectedPriceId ? 'accent' : '';
});

const calculateAmount = (() => {
  calculatedAmount.value = plan.value.amount
  if (plan.value.discountedAmount > 0) {
    calculatedAmount.value = plan.value.discountedAmount
  }
  if (plan.value.promoDiscount > 0) {
    calculatedAmount.value = plan.value.promoAmount
  }
})

const applyDiscount = async () => {
  if (!props.promoCode) return
  isLoading.value = true

  const options = {
    promoCode: props.promoCode,
  }
  requestEstimateCreate(plan.value.priceId, options)
    .then((estimateInvoice) => {
      console.log(estimateInvoice)
      plan.value.promoAmount = estimateInvoice.total / 100
      plan.value.promoDiscount = estimateInvoice.discount / 100
      plan.value.promoDurationInMonths = estimateInvoice.discountDurationInMonths
      plan.value.coupon = estimateInvoice.coupon
      calculateAmount()
    })
    .catch((error) => console.error(error))
    .finally(() => isLoading.value = false)
}

onMounted(() => {
  calculateAmount()
  applyDiscount()
})
</script>

<template>
  <div><v-card
    class="ma-1"
    :color="cardColor"
    min-width="300px"
    min-height="215px"
    hover
  >
    <ProgressOverlay
      height="215px"
      :isLoading="isLoading"
    >
      <v-card-title>{{ plan.title }}</v-card-title>
      <v-card-text class="text-h6">
        <div>
          <span v-if="calculatedAmount === 0">
            <span class="amount">Free</span>
          </span>
          <span v-else>
            $<span class="amount">{{ calculatedAmount }}</span>/month
          </span>
        </div>

        <div v-if="plan.promoDiscount" class="ma-0">
          <div v-if="plan.coupon.duration === 'forever'">
            <div><s>${{ plan.amount }}/month</s></div>
          </div>
          <div v-else-if="plan.coupon.duration === 'once'">
            <div class="font-weight-black">For your first month</div>
            <div>${{ plan.discountedAmount }}/month after</div>
          </div>
          <div v-else-if="plan.coupon.duration === 'repeating'">
            <div v-if="plan.coupon.durationInMonths > 1">
              <div class="font-weight-black">For {{ plan.coupon.durationInMonths }} months</div>
              <div>${{ plan.discountedAmount }}/month after</div>
            </div>
            <div v-else>
              <div class="font-weight-black">For your first month</div>
              <div>${{ plan.discountedAmount }}/month after</div>
            </div>
          </div>
        </div>
        <div v-else-if="plan.discountedAmount > 0">
          <div><s>${{ plan.amount }}/month</s></div>
          <div v-if="plan.discountText">{{ plan.discountText }}</div>
        </div>
      </v-card-text>
    </ProgressOverlay>
  </v-card></div>
</template>

<style scoped>
.amount {
  font-size: 3em;
  font-weight: bold;
}
</style>