import { createVuetify } from 'vuetify'

export const brightOrangeTheme = {
  dark: false,
  colors: {
    primary: '#C1554E', // Pomegranate
    secondary: '#333333', // Carbon
    'on-secondary': '#DFE0DF', // Rock Candy
    tertiary: '#5A6F8C', // Waikawa Gray
    accent: '#2F4858', // Blue Wing Teal
    'accent-light': '#e6edf2', // Morning Mist
    surface: '#fafafa', // Dr. White
    'surface-light': '#ffffff', // Morning Mist
    'surface-variant': '#DFE0DF', // Rock Candy
    'surface-variant-light': '#f4f5f4', // Lighthouse
    'on-surface-variant-light': '#333333', // Carbon
    // error: '#DFE0DF', // Rock Candy
    // warning: '#232323', // Black Bean
    // info: '#FBBF57', // Amber
    // success: '#2F4858', // Blue Wing Teal
    'text-dark': '#2F4858', // Blue Wing Teal
    'text-light': '#DFE0DF', // Rock Candy
    background: '#FBBF57', // Amber
  },
  variables: {
    'card-primary-border-style': 'solid',
    'card-primary-border-width': '1px',
    'card-primary-border-color': '#000000',
    'card-primary-padding': '8px', // pa-2
    'card-primary-padding-top': '20px', // pt-5
  }
}

const vuetify = createVuetify({
  theme: {
    defaultTheme: 'brightOrangeTheme',
    themes: {
      brightOrangeTheme,
    },
  },
})

export default vuetify