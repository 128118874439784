<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { requestCustomerUpdate } from '@/composables/useBillingCustomer'
import { requestSubscriptionCreate } from '@/composables/useBillingSubscription'
import { formatCurrency } from '@/utils/currencyFormatter'

const addressError = ref(null)
const paymentError = ref(null)

const router = useRouter()
const props = defineProps({
  plan: {
    type: Object,
    required: true
  },
  promoCode: {
    type: String,
    default: undefined
  },
  checkoutLabel: {
    type: String,
    default: 'Agree & Subscribe'
  }
});

const discountedAmount = props.plan.discountedAmount || props.plan.amount
const todaysAmount = computed(() => {
  let value = discountedAmount
  if (props.plan.promoDiscount && props.plan.coupon) {
    value = props.plan.promoAmount
  }
  return value.toFixed(2)
})
const todaysAmountNotes = computed(() => {
  let value = ""
  if (props.plan.promoDiscount && props.plan.coupon) {
    if (props.plan.coupon.duration === "repeating") {
      if (props.plan.coupon.durationInMonths > 1) {
        value += `For your first ${props.plan.promoDurationInMonths} months`
      } else {
        value += "For your first month"
      }
    } else if (props.plan.coupon.duration === "once") {
      value += "For your first month"
    }
  }
  return value
})
const standardPricing = computed(() => {
  let value = ""
  if (props.plan.promoDiscount && props.plan.coupon) {
    if (props.plan.coupon.duration === "forever") {
      value = `Regular Price: ${ formatCurrency(discountedAmount) }/month`
    } else {
      value = `${ formatCurrency(discountedAmount) }/month (plus tax where applicable) after promotion period`
    }
  }
  return value
})
const isDiscountForever = computed(() => {
  return (props.plan.coupon && props.plan.coupon.duration === "forever")
})
const isPaymentRequired = props.plan.promoAmount !== 0

const terms = computed(() => {
  let value = `By clicking "${props.checkoutLabel}," you are enrolling in automatic payments that will continue until you cancel. You can cancel at any time, effective at the end of the billing period. There are no refunds or credits for partial months.`

  if (!isPaymentRequired) {
    value = `By clicking "${props.checkoutLabel}," you are enrolling in a promotional subscription. Enable automatic payments to continue your subscription after the promotion ends.`
  }

  return value
})

const options = {
  mode: 'subscription',
  currency: 'usd',
  amount: discountedAmount * 100,

  // clientSecret: props.clientSecret,
  // Fully customizable with appearance API.
  // appearance: {/*...*/},
};
const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
const elements = stripe.elements(options)
const paymentElement = elements.create('payment', {
  terms: {
    card: 'never'
  }
})
const addressElement = elements.create('address', {
  mode: 'billing'
});

// TODO: implement updateEstimate to recalculate taxes and total as address changes
// const { requestUpdatedEstimate } = useBillingCustomerUpdate()
// async function updateEstimate(addressEvent) {
//   const customerDetails = event.value
//   const {tax, total} = await requestUpdatedEstimate(
//     customerDetails.address.line1,
//     customerDetails.address.line2,
//     customerDetails.address.city,
//     customerDetails.address.state,
//     customerDetails.address.postal_code,
//     customerDetails.address.country
//   )
//   elements.update({ amount: total})
// }

async function handleBillingAddress() {
  addressError.value = null
  const options = {}
  const { value: customer } = await addressElement.getValue()
  const filterNullValues = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null))
  }
  if (customer.name) options.name = customer.name
  if (customer.address) {
    options.address = filterNullValues({
      line1: customer.address.line1,
      line2: customer.address.line2,
      city: customer.address.city,
      state: customer.address.state,
      postalCode: customer.address.postal_code,
      country: customer.address.country,
    })
  }
  return requestCustomerUpdate(options)
}

const createSubscription = async () => {
  const options = {
    promoCode: props.promoCode,
  }
  return requestSubscriptionCreate(props.plan.priceId, options)
    .then((data) => {
      return data.clientSecret
    })
}

async function handleCheckoutEvent(clientSecret) {
  const returnUrl = `${window.location.origin}${router.resolve({ name: 'UserAccount' }).href}`
  console.log (returnUrl)
  const response = await stripe.confirmPayment({
    elements,
    clientSecret,
    confirmParams: {
      return_url: returnUrl,
    },
    redirect: 'if_required'
  });

  if (response.error) {
    paymentError.value = response.error.message
    paymentElement.focus()
    throw(response.error)
  } else {
    // Your customer will be redirected to your `return_url`. For some payment
    // methods like iDEAL, your customer will be redirected to an intermediate
    // site first to authorize the payment, then redirected to the `return_url`.
  }
}

async function submit() {

  const { error } = await elements.submit();
  if (error) {
      console.error(error)
      if(error.code === 'incomplete_address') {
        addressError.value = 'Your address is incomplete'
        addressElement.focus()
      }
      throw error
  }

  await handleBillingAddress() 
    .catch((error) => {
      addressError.value = error.displayMessage
      addressElement.focus()
      console.error(error)
      throw error
    })

  const clientSecret = await createSubscription()
    .catch((error) => {
      console.error(error)
      throw error
    })

  if (isPaymentRequired) {
    await handleCheckoutEvent(clientSecret)
  }
}

defineExpose({
  submit
})

function managePaymentElements() {
  addressElement.mount('#address-element')
  addressElement.on('change', (event) => {
    if (event.error) addressError.value = event.error.message
    else addressError.value = null
  })
  if (isPaymentRequired) {
    paymentElement.mount('#payment-element')
    paymentElement.on('change', (event) => {
      if (event.error) paymentError.value = event.error.message
      else paymentError.value = null
    })
  }
}

onMounted(() => {
  managePaymentElements()
})
</script>

<template>
  <v-sheet class="mx-auto">
    <v-card class="my-4" color="surface-variant-light">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" class="text-left">
            <div class="text-h5">Crowdfunding Backer</div>
            <div v-if="standardPricing">
              <s v-if="isDiscountForever">{{ standardPricing }}</s>
              <span v-else>{{ standardPricing }}</span>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="text-right">
            <div class="text-right">
              <div><b>$</b><span class="text-h4">{{ todaysAmount }}</span>/month</div>
              <div><b>{{ todaysAmountNotes }}</b></div>
              <div v-if="todaysAmount > 0" class="text-caption text-medium-emphasis">plus tax where applicable</div>
            </div>
          </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <div class="mt-2 text-left">No commitments, cancel anytime</div>
      </v-card-text>
    </v-card>

    <!-- Address and payment elements inserted by Stripe SDK here -->
    <v-card class="my-4 overflow-visible" color="surface-variant-light">
      <v-card-title class="text-left">
        Billing Address
        <div v-if="!isPaymentRequired" class="text-left text-caption text-medium-emphasis">
          Required for sales tax regulation compliance
        </div>
        <v-divider class="my-2"></v-divider>
      </v-card-title>
      <v-card-text>
        <v-fade-transition>
          <v-expand-transition>
            <div v-if="addressError" class="text-error text-left mb-2">{{ addressError }}</div>
          </v-expand-transition>
        </v-fade-transition>
        <div id="address-element" class="pa-2 rounded" :class="{'error': addressError}"></div>
      </v-card-text>
    </v-card>
    <v-card v-if="isPaymentRequired" class="my-4" color="surface-variant-light">
      <v-card-title class="text-left">
        Payment Information
        <v-divider class="my-2"></v-divider>
      </v-card-title>
      <v-card-text>
        <v-fade-transition>
          <v-expand-transition>
            <div v-if="paymentError" class="text-error text-left mb-2">{{ paymentError }}</div>
          </v-expand-transition>
        </v-fade-transition>
        <div id="payment-element" class="pa-2 rounded" :class="{'error': paymentError}"></div>
      </v-card-text>
    </v-card>

    <div id="terms" class="text-left text-caption text-medium-emphasis mx-4 mt-8">
      {{ terms }}
    </div>
  </v-sheet>
</template>

<style scoped>
.overflow-visible {
  overflow: visible;
  position: relative;
  z-index: 1;
}
#address-element.error,
#payment-element.error  {
  border: 2px solid #df1b41;
}
/* Override text-error to match Stripe SDK error text */
.text-error {
  color: #df1b41 !important;
  font-size: 0.93rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif
}
</style>