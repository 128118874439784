<script setup>
import { ref } from 'vue'
import RegisterPlanCard from '@/components/RegisterPlanCard.vue'
import PlanService from '@/utils/PlanService.js'

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  promoCode: {
    type: String,
    default: undefined
  },
});

const selectedPriceId = defineModel('priceId')
const selectedPlan = defineModel('selectedPlan')
const plans = ref(PlanService.getPlans())
const defaultPlan = PlanService.getDefaultPlan()
selectedPriceId.value = defaultPlan.priceId;
selectedPlan.value = defaultPlan

const setSelectedPlan = (plan) => {
  selectedPlan.value = plan
  selectedPriceId.value = plan.priceId
}
</script>

<template>
  <div class="mt-3 mb-6">
    Support AdventuRAWR and play now with our Crowdfunding subscription
  </div>
  <v-sheet class="mx-auto mt-4">
    <v-row justify="center">
      <v-col
        v-for="(plan, index) in plans"
        :key="plan.priceId"
        cols="12"
        md="6"
        lg="4"
      >
        <RegisterPlanCard
          :selectedPriceId="selectedPriceId"
          :promoCode="promoCode"
          v-model:plan="plans[index]"
          @click="() => setSelectedPlan(plan)"
        />
      </v-col>
    </v-row>
  </v-sheet>
  <div class="mt-3 mb-6">
    No commitments, cancel anytime 
  </div>
  <v-sheet 
    class="mt-10 mb-6 mx-auto text-left text-caption text-medium-emphasis" 
    max-width="700"
  >
    As an early access game, AdventuRAWR is still under development. You may encounter bugs, performance issues, and missing features. Your feedback is crucial to help us improve the game. Please visit <a href="https://www.adventurawr.ai/crowdfunding" target="_blank">AdventuRAWR Crowdfunding</a> for more details.
</v-sheet>
</template>