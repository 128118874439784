import { getAuth } from "firebase/auth";
import { createWebHistory, createRouter } from "vue-router";
import { useStore } from 'vuex'
import { appBarStore } from "@/store/AppBarStore";
import Login from '@/views/Login.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import Register from '@/views/Register.vue';
import Home from '@/views/Home.vue';
import Pending from '@/views/Pending.vue';
import Adventure from '@/views/adventure/Adventure.vue';
import AdventureList from '@/views/adventure/AdventureList.vue';
import AdventureCreate from '@/views/adventure/AdventureCreate.vue';
import AdventureJoin from '@/views/adventure/AdventureJoin.vue';
import UserProfile from '@/views/user/UserProfile.vue';
import UserAccount from '@/views/user/UserAccount.vue';
import UserAuthAction from '@/views/user/UserAuthAction.vue';
import UserSubscription from '@/views/user/UserSubscription.vue';
import UserSubscriptionCancel from '@/views/user/UserSubscriptionCancel.vue';
import UserSubscriptionRenew from '@/views/user/UserSubscriptionRenew.vue';
import UserPaymentUpdate from '@/views/user/UserPaymentUpdate.vue';
import UserSubscriptionActive from '@/views/user/UserSubscriptionActive.vue';

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/pending",
    name: "Pending",
    component: Pending,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/login/reset",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/register/:promoCode?",
    name: "Register",
    component: Register,
  },
  {
    path: "/adventure/new",
    name: "AdventureCreate",
    component: AdventureCreate,
    meta: {
      requiresAuth: true,
      requiresRegistration: true,
    }
  },
  {
    path: "/adventure/:id?",
    name: "Adventure",
    component: Adventure,
    meta: {
      requiresAuth: true,
      requiresRegistration: true,
    }
  },
  {
    path: "/adventure/join/:id?",
    name: "AdventureJoin",
    component: AdventureJoin,
    meta: {
      requiresAuth: true,
      requiresRegistration: true,
    }
  },
  {
    path: "/adventure/archives",
    name: "AdventureList",
    component: AdventureList,
    meta: {
      requiresAuth: true,
      requiresRegistration: true,
    }
  },
  {
    path: "/user/action",
    name: "UserAuthAction",
    component: UserAuthAction,
  },
  {
    path: "/user/profile",
    name: "UserProfile",
    component: UserProfile,
    meta: {
      requiresAuth: true,
      requiresRegistration: true,
    }
  },
  {
    path: "/user/account",
    name: "UserAccount",
    component: UserAccount,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/user/subscription",
    name: "UserSubscription",
    component: UserSubscription,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/user/subscription/active",
    name: "UserSubscriptionActive",
    component: UserSubscriptionActive,
  },
  {
    path: "/user/subscription/cancel",
    name: "UserSubscriptionCancel",
    component: UserSubscriptionCancel,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/user/subscription/renew",
    name: "UserSubscriptionRenew",
    component: UserSubscriptionRenew,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/user/payment/update",
    name: "UserPaymentUpdate",
    component: UserPaymentUpdate,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: "Home" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  const auth = getAuth()
  const store = useStore()
  const currentUser = auth.currentUser

  appBarStore.value.clear();

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth && !currentUser) {
    return { name: 'Login'}
  }

  const requiresRegistration = to.matched.some(record => record.meta.requiresRegistration)
  if (requiresRegistration && !store.state.isRegistrationComplete) {
    return { name: 'Register'}
  }
})

export default router;
