<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { getAuth, applyActionCode } from 'firebase/auth'

const store = useStore()
const props = defineProps({
  actionCode: {
    type: String,
    default: undefined
  },
  continueUrl: {
    type: String,
    default: undefined
  }
});

const isError = defineModel('isError')
const isActionCodeValid = ref(true)

function relativeUrl(url) {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return new URL(url).pathname;
  }
  return url.startsWith('/') ? url : '/' + url;
}
const relativeContinueUrl = ref(relativeUrl(props.continueUrl));

function extractPromoCode(url) {
  const match = url.match(/\/register\/([^/]+)/);
  return match ? match[1] : null;
}
const promoCode = extractPromoCode(props.continueUrl);

const auth = getAuth()

const verifyEmail = async () => {
  applyActionCode(auth, props.actionCode).then(() => {
    if (auth.currentUser) {
      auth.currentUser.reload()
    } else {
      store.dispatch('setPromoCode', promoCode)
      relativeContinueUrl.value = '/login'
    }
  }).catch((error) => {
    switch (error.code) {
      case "auth/invalid-action-code":
        isActionCodeValid.value = false
        break
      default:
        isError.value = true
        break
    }
  })
}

onMounted(() => {
  verifyEmail()
})
</script>

<template>
  <v-sheet v-if="!isActionCodeValid" class="mx-auto mt-10">
    <p class="text-h4">Try verifying your email again</p>
    <p class="my-6">Your request to verify your email has expired or the link has already been used</p>
  </v-sheet>

  <v-sheet v-else class="mx-auto mt-4 mt-10">
    <p class="text-h4">Your email has been verified</p>
    <p class="my-6">You can now sign in with your account</p>

    <v-btn
      class="my-10"
      type="submit"
      color="primary"
      rounded="xl"
      size="large"
      :to="relativeContinueUrl"
      flat
    > Continue </v-btn>
  </v-sheet>
</template>
