export function getPaymentMethodString(paymentMethod) {
  if (!paymentMethod || !paymentMethod.last4) return '';
  return `**** **** **** ${paymentMethod.last4}`;
}

export function getPaymentMethodIcon(paymentMethod) {
  if (!paymentMethod || !paymentMethod.brand) return '';
  switch (paymentMethod.brand.toLowerCase()) {
    case 'visa':
      return 'mdi-credit-card-outline'; // Example icon, replace with actual icon
    case 'mastercard':
      return 'mdi-credit-card-outline'; // Example icon, replace with actual icon
    case 'amex':
      return 'mdi-credit-card-outline'; // Example icon, replace with actual icon
    // Add more cases as needed
    default:
      return 'mdi-credit-card-outline'; // Default icon
  }
}