<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { appBarStore } from '@/store/AppBarStore.js'
import UserSubscriptionDetails from '@/components/UserSubscriptionDetails.vue';
import UserSubscriptionPaymentDetails from '@/components/UserSubscriptionPaymentDetails.vue';

const store = useStore()

const subscription = computed(() => store.state.subscription.subscription)

appBarStore.value.setTitle('Subscription')

</script>

<template>
  <v-card
    max-width="800"
    class="mx-auto mt-4 text-start card-primary"
  >
    <v-card-title class="d-flex">
      <h2 class="text-h5">Subscription</h2>
    </v-card-title>

    <v-card-text>
      <p class="text-subtitle-1">Plan Details</p>
      <UserSubscriptionDetails />

      <p class="text-subtitle-1">Payment Info</p>
      <UserSubscriptionPaymentDetails />

      <div class="mb-4">
        <v-btn v-if="subscription && subscription.status === 'active'"
          class="mt-8 me-1"
          variant="outlined"
          color="primary"
          flat
          block
          :to="{ name: 'UserSubscriptionCancel' }"
        > Cancel Subscription </v-btn>
      </div>
    </v-card-text> 
  </v-card>
</template>
