<script setup>
import { onMounted, ref } from 'vue'
import {
  getAuth, 
  verifyPasswordResetCode,
  confirmPasswordReset,
} from 'firebase/auth'

const props = defineProps({
  actionCode: {
    type: String,
    default: undefined
  },
  continueUrl: {
    type: String,
    default: undefined
  }
});

const isError = defineModel('isError')
const isActionCodeValid = ref(true)
const isPasswordUpdated = ref(false)
const isSaving = ref(false)
const accountEmail = ref()
const password = ref()

const auth = getAuth()

const handleSavePassword = async () => {
  console.log("saving password")
  console.log(password.value)
  isSaving.value = true
  confirmPasswordReset(auth, props.actionCode, password.value)
    .then(() => {
      isPasswordUpdated.value = true
    })
    .catch((error) => {
      switch (error.code) {
        case "auth/invalid-action-code":
          isActionCodeValid.value = false
          break
        default:
          isError.value = true
          break
      }
    })
    .finally(() => isSaving.value = false)
}

const verifyCode = async () => {
  verifyPasswordResetCode(auth, props.actionCode).then((email) => {
    accountEmail.value = email;
  }).catch((error) => {
    switch (error.code) {
      case "auth/invalid-action-code":
        isActionCodeValid.value = false
        break
      default:
        isActionCodeValid.value = false
        break
    }
  })
}

onMounted(() => {
  verifyCode()
})
</script>

<template>
  <v-sheet v-if="!isActionCodeValid" class="mx-auto mt-10">
    <p class="text-h4">Try resetting your password again</p>
    <p class="my-6">Your request to reset your password has expired or the link has already been used</p>
    <v-btn
      class="my-10"
      type="submit"
      color="primary"
      rounded="xl"
      size="large"
      :to="{name: 'ResetPassword'}"
      flat
    > Reset Password </v-btn>
  </v-sheet>

  <v-sheet v-else-if="isPasswordUpdated" class="mx-auto mt-10">
    <p class="text-h4">Password Updated</p>
    <p class="my-6">You can now sign in with your new password</p>
    <v-btn
      class="my-10"
      type="submit"
      color="primary"
      rounded="xl"
      size="large"
      :to="{name: 'Login'}"
      flat
    > Continue </v-btn>
  </v-sheet>

  <v-sheet v-else class="mx-auto mt-4 mt-10">
    <p class="text-h4">Reset your Password</p>
    <p class="my-6">for <span class="font-weight-bold">{{ accountEmail }}</span></p>

    <v-form @submit.prevent="handleSavePassword">
      <v-text-field
          class="mx-auto"
          width="300" 
          label="New Password"
          type="password"
          v-model="password"
          :disabled="isSaving"
          :error-messages="props.errorMessage"
        ></v-text-field>

      <v-btn
        class="my-10"
        type="submit"
        color="primary"
        rounded="xl"
        size="large"
        :loading="isSaving"
        flat
      > Save </v-btn>
    </v-form>
  </v-sheet>
</template>
