import { api } from '@/utils/api.js'

export function useBillingPortal() {
  const createSession = async (returnUrl) => {
    return api.billing.portal.session.create(returnUrl)
      .then((response) => {
        const url = response.data.url
        return url
      })
      .catch((error) => {
        console.error(error)
        switch (error.response.status) {
          case 401:
            error.displayMessage = "Session has expired"
            break
          default:
            error.displayMessage = "Unable to create billing portal session"
        }
        throw error
      })
  }

  return {
    createSession
  }
}