import { api } from '@/utils/api.js'

export function useBillingInvoice() {
  const getLatestInvoice = async () => {
    return api.billing.invoice.get()
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.error(error)
        switch (error.response.status) {
          case 401:
            error.displayMessage = "Session has expired"
            break
          default:
            error.displayMessage = "Unable to get invoice"
        }
        throw error
      })
  }

  return {
    getLatestInvoice
  }
}