import { api } from '@/utils/api.js'

export async function requestEstimateCreate(
  priceId,
  options = {},
) {
  return api.billing.estimate.create(priceId, options)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.error(error)
      switch (error.response.status) {
        case 401:
          error.displayMessage = "Session has expired"
          break
        default:
          error.displayMessage = "Unable to create estimate"
      }
      throw error
    })
}