import { api } from '@/utils/api.js'

const state = {
  subscription: null,
  error: null,
}

const getters = {
  subscription: (state) => state.subscription,
  error: (state) => state.error,
}

const actions = {
  async fetchSubscription({ commit }) {
    commit('setError', null)
    try {
      const response = await api.billing.subscription.get()
      commit('setSubscription', response.data)
    } catch (error) {
      console.error(error)
      let displayMessage = "Unable to load subscription"
      if (error.response && error.response.status === 401) {
        displayMessage = "Session has expired"
      }
      commit('setError', displayMessage)
      throw error
    }
  },
  async cancelSubscription({ commit }) {
    commit('setError', null)
    try {
      const response = await api.billing.subscription.cancel()
      commit('setSubscription', response.data)
    } catch (error) {
      console.error(error)
      let displayMessage = "Unable to delete subscription"
      if (error.response && error.response.status === 401) {
        displayMessage = "Session has expired"
      }
      commit('setError', displayMessage)
      throw error
    }
  },
}

const mutations = {
  setSubscription(state, subscription) {
    state.subscription = subscription
  },
  setError(state, error) {
    state.error = error
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}