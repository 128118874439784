import plans from '@/configs/planConfig.js';

class PlanService {
  constructor() {
    const planConfiguration = process.env.VUE_APP_STRIPE_PLAN_CONFIGURATION || "production"
    this.plans = plans[planConfiguration];

    let defaultPlan = this.plans.find(plan => plan.isDefault === true);
    if (!defaultPlan && this.plans.length > 0) {
      defaultPlan = this.plans[0];
    }
    this.defaultPlan = defaultPlan;
  }

  getPlans() {
    return this.plans;
  }

  getDefaultPlan() {
    return this.defaultPlan;
  }

  getPlanByPriceId(priceId) {
    return this.plans.find(plan => plan.priceId === priceId);
  }

}

export default new PlanService();