// TODO: populate receipt from latest invoice composable<script setup>
import { ref, onMounted } from 'vue'
import { useBillingInvoice } from '@/composables/useBillingInvoice'
import { formatSubunitToCurrency } from '@/utils/currencyFormatter'
import ProgressOverlay from '@/components/ProgressOverlay'
import PlanService from '@/utils/PlanService.js'


const isLoading = ref(false)
const error = ref(null)
const plan = ref(null)
const invoice = ref(null)
const discountNotes = ref(null)

const { getLatestInvoice } = useBillingInvoice();
const loadInvoice = async () => {
  isLoading.value = true
  error.value = null
  getLatestInvoice()
    .then((latestInvoice) => {
      plan.value = PlanService.getPlanByPriceId(latestInvoice.planId)
      invoice.value = latestInvoice
      generateDiscountLabel(latestInvoice)
    })
    .catch((error) => {
      error.value = "Unable to load receipt"
      console.error(error)
    })
    .finally(() => isLoading.value = false)
}

const generateDiscountLabel = (invoice) => {
  const coupon = invoice.coupon
  if (!coupon) return;
  
  let discount;
  if (coupon.amountOff) {
    discount = `$${(coupon.amountOff / 100)}`
  } else if (coupon.percentOff) {
    discount = `${coupon.percentOff}%`
  }

  let duration;
  switch (coupon.duration) {
    case "forever":
      duration = "until your subscription ends"
      break
    case "once":
      duration = "your first month"
      break
    case "repeating":
      if (coupon.durationInMonths > 1) {
        duration = `your first ${coupon.durationInMonths} months`
      } else {
        duration = "your first month"
      }
      break
  }

  if (discount && duration) {
    discountNotes.value = `${discount} off ${duration}`
  }
}

onMounted(() => {
  loadInvoice()
})
</script>

<template>
  <v-card
    color="surface-light"
    class="mx-auto my-2 pa-6 text-body-1"
    max-width="800"
  >
    <ProgressOverlay
      :isLoading="isLoading"
    > 
      <div v-if="invoice">

        <v-container class="mb-4 py-0 text-left">
          <p v-if="plan">
            <span class="text-h6">{{ plan.title }}</span> 
          </p>
          <p>
            ${{ plan.discountedAmount }} billed monthly (plus tax where applicable)
          </p>
        </v-container>

        <v-container class="py-0">
          <v-row no-gutters>
            <v-divider class="my-2"></v-divider>

            <v-col>
              <div class="px-4 py-1 text-left">
                <p class=" text-h6">Subtotal</p>
              </div>
            </v-col>
            <v-col>
              <div class="px-4 py-1 text-right font-weight-bold">
                {{ formatSubunitToCurrency(invoice.subtotal) }}
              </div>
            </v-col>

            <v-responsive width="100%"></v-responsive>

            <v-col v-if="invoice.discount">
              <div class="px-4 py-1 text-left">
                <p class="">Discounts</p>
                <p class="text-caption">{{ discountNotes }}</p>
              </div>
            </v-col>

            <v-col v-if="invoice.discount">
              <div class="px-4 py-1 text-right">
                -{{ formatSubunitToCurrency(invoice.discount) }}
              </div>
            </v-col>

            <v-responsive width="100%"></v-responsive>

            <v-col>
              <div class="px-4 py-1 text-left">
                <p class="">Taxes</p>
              </div>
            </v-col>

            <v-col>
              <div class="px-4 py-1 text-right">
                {{ formatSubunitToCurrency(invoice.tax) }}
              </div>
            </v-col>

            <v-divider class="my-2"></v-divider>

            <v-col>
              <div class="px-4 py-1 text-left">
                <p class=" text-h6">Total paid today</p>
              </div>
            </v-col>

            <v-col>
              <div class="px-4 py-1 text-right font-weight-bold">
                {{ formatSubunitToCurrency(invoice.total) }}
              </div>
            </v-col>
          </v-row>
        </v-container>

      </div>
      <div v-if="error">
        <p class="text-error">{{ error }}</p>
      </div>
    </ProgressOverlay>
  </v-card>
</template>

<style scoped>
.no-padding {
  padding: 0;
}
</style>
