<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import ProgressOverlay from '@/components/ProgressOverlay'

const store = useStore()

const isLoading = ref(false)
const subscription = computed(() => store.state.subscription.subscription)
const error = computed(() => store.state.subscription.error)

const loadSubscription = async () => {
  isLoading.value = true
  store.dispatch('subscription/fetchSubscription')
    .catch((error) => console.error(error))
    .finally(() => isLoading.value = false)
}

onMounted(() => {
  loadSubscription()
})
</script>

<template>
  <v-card
    color="surface-light"
    class="mx-auto my-2 pa-6 text-body-1"
  >
    <ProgressOverlay
      :isLoading="isLoading"
    > 
      <div v-if="subscription">
        <p><span class="text-h6">Crowdfunding Backer</span></p>
        <p class="my-1 mb-2">400 turns every month</p>
        <v-divider class="my-4"></v-divider>
        <p class="my-1 mb-2">${{ subscription.amount / 100 }}/month (plus tax where applicable)</p>
      </div>
      <div v-else>
        <p class="text-error">{{ error }}</p>
      </div>
    </ProgressOverlay>
  </v-card>
</template>

<style scoped>
.no-padding {
  padding: 0;
}
</style>
