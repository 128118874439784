<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { ref, computed, onMounted } from 'vue'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { appBarStore } from '@/store/AppBarStore.js'
import ProgressSheet from '@/components/ProgressSheet.vue'
import RegisterAccount from '@/components/RegisterAccount.vue'
import RegisterPlan from '@/components/RegisterPlan.vue'
import ProgressOverlay from '@/components/ProgressOverlay.vue'
import RegisterVerify from '@/components/RegisterVerify.vue'
import FormSubscriptionCheckout from '@/components/FormSubscriptionCheckout.vue'

const RegisterSteps = {
  ACCOUNT: "1",
  VERIFY: "2",
  PLAN: "3",
  CHECKOUT: "4"
}

const isResuming = ref(true)
const isLoading = ref(false);
const isError = ref(false);
const email = ref('')
const password = ref('')
const priceId = ref('') // TODO: remove?
const selectedPlan = ref(null)
// TODO: Unclear there is a use case for resuming mid-subscription (see resumeRegistrationStep)
// const clientSecret = ref('')
const errorMessage = ref('')
const checkoutComponent = ref(null)
const activeStep = ref(RegisterSteps.ACCOUNT)

const auth = getAuth()
const store = useStore()
const router = useRouter() // get a reference to our vue router
const route = useRoute()

let promoCode = route.params.promoCode
if (!promoCode && store.state.promoCode) {
  promoCode = store.state.promoCode
  router.replace({ 
    name: 'Register',
    params: { promoCode: store.state.promoCode }
  })
}
console.log(promoCode)

appBarStore.value.update(
  'Register',
  [
    {
      label: 'Login',
      icon: 'mdi-login',
      routeName: 'Login'
    }
  ]
)

const resumeRegistrationStep = async () => {
  if (!store.state.user) return RegisterSteps.ACCOUNT
  if (!auth.currentUser.emailVerified) return RegisterSteps.VERIFY

  let resumeStep = RegisterSteps.PLAN
  // TODO: Unclear there is a use case for resuming mid-subscription
  // Checkout flow now creates the user, subscription, and checksout in one click
  // const { getClientSecret } = useBillingCheckout();
  //   await getClientSecret()
  //     .then((subscription) => {
  //       if (subscription) {
  //         priceId.value = subscription.priceId
  //         clientSecret.value = subscription.clientSecret
  //         resumeStep = RegisterSteps.CHECKOUT
  //       }
  //     })
  //     .catch((error) => console.error(error))
  return resumeStep
}

const resumeRegistration = async () => {
  const resumeStep = await resumeRegistrationStep()
  isResuming.value = false
  activeStep.value = resumeStep
}

const previousStep = () => {
  let step = Number(activeStep.value)
  if (step > 1) {
    step--
  }
  activeStep.value = step.toString()
  errorMessage.value = ''
}

const stepperActionsDisabled = computed(() => {
  if (isLoading.value || isResuming.value) {
    return true
  }

  let step = Number(activeStep.value)
  switch(step) {
    case 1: return 'prev'
    default: return false
  }
})
const checkoutLabel = 'Subscribe'
const nextButtonText = computed(() => {
  let step = Number(activeStep.value)
  switch(step) {
    case Number(RegisterSteps.CHECKOUT): return checkoutLabel
    default: return 'Next'
  }
})
const nextStep = () => {
  errorMessage.value = ''
  let step = Number(activeStep.value)
  switch (step) {
    case Number(RegisterSteps.ACCOUNT):
      handleCreateUser()
      break
    case Number(RegisterSteps.VERIFY):
      handleVerifyEmail()
      break
    case Number(RegisterSteps.CHECKOUT):
      handleCheckout()
      break
    default:
      step++
      activeStep.value = step.toString()
  }
}
const isStepComplete = (step) => {
  return Number(activeStep.value) > Number(step)
}

const handleCreateUser = () => {
  isLoading.value = true;
  createUserWithEmailAndPassword(auth, email.value, password.value)
  .then((data) => {
    store.dispatch('setUser', data.user.uid)
    store.dispatch('setToken', data.user.accessToken)
    activeStep.value = RegisterSteps.VERIFY
  })
  .catch(error => {
    console.error(error)
    switch (error.code) {
      case 'auth/email-already-in-use':
        errorMessage.value = 'Email address already registered'
        break
      case 'auth/invalid-email':
        errorMessage.value = 'Invalid email'
        break
      case 'auth/operation-not-allowed':
        errorMessage.value = 'Email registration disabled'
        break  
      case 'auth/weak-password':
        errorMessage.value = 'Password not strong enough'
        break  
      case 'auth/missing-password':
        errorMessage.value = 'Password required'
        break  
      default:
        errorMessage.value = 'Unable to process registration'
        break
    }
    isError.value = true
  })
  .finally(() => {
    isLoading.value = false
  });
}

const handleVerifyEmail = async () => {
  isLoading.value = true

  if (!auth.currentUser.emailVerified) {
    await auth.currentUser.reload()
  }

  if (auth.currentUser.emailVerified) {
      activeStep.value = RegisterSteps.PLAN
  } else {
    isError.value = true
    errorMessage.value = "Email verification required"
  }

  isLoading.value = false
}

const handleCheckout = async () => {
  isLoading.value = true
  await checkoutComponent.value.submit()
    .then(() => router.push({name: "UserSubscriptionActive"}))
    .catch((error) => console.error(error))
    .finally(() => console.log(isLoading.value = false))
}

onMounted(() => {
  resumeRegistration()
})
</script>

<template>
  <!-- <v-card class="mx-auto mt-4 card-primary">
    <h1 class="mt-4 mb-6">Sign Up</h1> -->

    <ProgressOverlay
     :isLoading="isResuming"
     >
    <v-stepper
      v-model="activeStep" 
      class="mx-auto mt-4 view-primary d-flex flex-column"
    >
      <v-stepper-header>
        <v-stepper-item
          title="Account"
          color="tertiary" 
          :value="RegisterSteps.ACCOUNT"
          :complete="isStepComplete(RegisterSteps.ACCOUNT)"
        ></v-stepper-item>

        <v-divider></v-divider>

        <v-stepper-item
          title="Verify"
          color="tertiary" 
          :value="RegisterSteps.VERIFY"
          :complete="isStepComplete(RegisterSteps.VERIFY)"
        ></v-stepper-item>

        <v-divider></v-divider>

        <v-stepper-item
          title="Plan"
          color="tertiary"
          :value="RegisterSteps.PLAN"
          :complete="isStepComplete(RegisterSteps.PLAN)"
        ></v-stepper-item>

        <v-divider></v-divider>

        <v-stepper-item
          title="Checkout"
          color="tertiary"
          :value="RegisterSteps.CHECKOUT"
          :complete="isStepComplete(RegisterSteps.CHECKOUT)"
        ></v-stepper-item>
      </v-stepper-header>

      <v-stepper-window>

        <v-stepper-window-item :value="RegisterSteps.ACCOUNT">
          <ProgressSheet :isLoading="isLoading">
            <RegisterAccount
                v-model:email="email"
                v-model:password="password"
                :errorMessage="errorMessage"
                :isDisabled="isLoading"
            />
          </ProgressSheet>
        </v-stepper-window-item>

        <v-stepper-window-item :value="RegisterSteps.VERIFY">
          <ProgressSheet :isLoading="isLoading">
            <RegisterVerify
            />
          </ProgressSheet>
        </v-stepper-window-item>

        <v-stepper-window-item :value="RegisterSteps.PLAN">
          <ProgressSheet :isLoading="isLoading">
            <RegisterPlan
              :promoCode = "promoCode"
              v-model:priceId="priceId"
              v-model:selectedPlan="selectedPlan"
            />
          </ProgressSheet>
        </v-stepper-window-item>

        <v-stepper-window-item :value="RegisterSteps.CHECKOUT">
          <ProgressSheet :isLoading="isLoading">
            <FormSubscriptionCheckout
              ref="checkoutComponent"
              :key="selectedPlan.priceId"
              :plan="selectedPlan"
              :promoCode="promoCode"
              :checkoutLabel="checkoutLabel"
            />
          </ProgressSheet>
        </v-stepper-window-item>

      </v-stepper-window>

      <v-spacer></v-spacer>
      <v-stepper-actions
        @click:prev="previousStep"
        @click:next="nextStep"
        :disabled=stepperActionsDisabled
        :next-text="nextButtonText"
      ></v-stepper-actions>

    </v-stepper>
  </ProgressOverlay>
  <!-- </v-card> -->

  <v-dialog id="errorDialog"
    v-model="isError"
    width="300"
  >
    <v-card>
      <v-card-text>
        {{ errorMessage }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="isError = false">Dismiss</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
#errorDialog {
  text-align: center;
}
</style>