<!-- eslint-disable vue/multi-word-component-names -->

<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getAuth } from 'firebase/auth'
import { appBarStore } from '@/store/AppBarStore.js'
import UserSubscriptionReceipt from '@/components/UserSubscriptionReceipt.vue'

appBarStore.value.setTitle('Register');

const isLoading = ref(false)
const startAdventureError = ref(null)

const auth = getAuth()
const router = useRouter()
const store = useStore()
if (store.state.token === null) {
  router.push('/')
}

const handleStartAdventure = async () => {
  isLoading.value = true
  await pollForClaim()
    .then(() => {
      router.push('/adventure')
    })
    .catch((error) => {
      // TODO: route to a error page indicating checkout was succesful
      // user should log in again (log user out here???)
      console.error('Failed to set claim:', error)
      startAdventureError.value = "Looks like we're still working on finalizing your account. Give it a moment and please try again."
    })
    .finally(() => {
      isLoading.value = false
    })
}

async function isRegistrationComplete() {
  const idTokenResult = await auth.currentUser.getIdTokenResult()
  const isRegistrationComplete = idTokenResult.claims.is_registration_complete || false
  store.dispatch('setIsRegistrationComplete', isRegistrationComplete)
  return isRegistrationComplete
}

let intervalId = null
const stopPolling = () => {
  if (intervalId !== null) {
    clearInterval(intervalId)
    intervalId = null
  }
}

const pollForClaim = async ({ 
  interval = 2000, 
  maxAttempts = 10,
  rejectOnMaxAttempts = true,
} = {}) => {
  const complete = await isRegistrationComplete()
  if (complete) {
    return Promise.resolve(true)
  } 

  let attempts = 0
  return new Promise((resolve, reject) => {
    stopPolling()
    intervalId = setInterval(async () => {
      attempts++
      await auth.currentUser.getIdToken(true)
      const complete = await isRegistrationComplete()
      if (complete) {
        stopPolling()
        resolve(true)
      }
      if (attempts >= maxAttempts) {
        stopPolling()
        if (rejectOnMaxAttempts) {
          reject(new Error('Claim not set within expected time'))
        } else {
          resolve(false)
        }
      }
    }, interval)
  })
}

onMounted(() => {
  pollForClaim({
    maxAttempts: 20,
    rejectOnMaxAttempts: false
  })
})

onUnmounted(() => {
  stopPolling()
})

</script>

<template>
  <v-card class="mx-auto mt-4 card-primary">
    <div class="ma-8 mt-8 text-center">
      <h1 class="my-1"> Woohoo! </h1>
      <h4 class="my-1"> Your subscription has been successfully activated </h4>
    </div>

    <div>
      <UserSubscriptionReceipt 
        class="mx-8"
      />
    </div>

    <div class="mx-8 my-10 text-center">
      <p class="my-6"> Thanks for supporting our crowdfunding campaign! It's time to play.</p>
    </div>

    <div class="mt-12">
      <v-btn
        class="me-4"
        type="submit"
        color="primary"
        rounded="xl"
        size="large"
        flat
        :loading="isLoading"
        @click="handleStartAdventure"
      > Start a New Adventure </v-btn>
    </div>
  </v-card>

  <v-dialog id="errorDialog"
    v-model="startAdventureError"
    width="300"
  >
    <v-card>
      <v-card-text>
        {{ startAdventureError }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="startAdventureError = null">Dismiss</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
