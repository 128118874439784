<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import UserAuthResetPassword from '@/components/UserAuthResetPassword.vue'
import UserAuthActionVerifyEmail from '@/components/UserAuthActionVerifyEmail.vue'

const isError = ref(false)

const route = useRoute()

const mode = route.query.mode
const oobCode = route.query.oobCode
const continueUrl = route.query.continueUrl
const fullUrl = window.location.href

// TODO: implement recover email
// const recognizedModes = ['resetPassword', 'recoverEmail', 'verifyEmail']
const recognizedModes = ['resetPassword', 'verifyEmail']
if (!recognizedModes.includes(mode)) {
  isError.value = true
}

</script>

<template>
  <v-card class="mx-auto mt-4 card-primary">
    <div v-if="isError">
      <v-sheet class="mx-auto mt-10">
        <p class="text-h4">Ruh-roh... Something isn't working</p>
        <div>
          <p class="my-6 text-error">Unable to process your request</p>
          <p>We are not able to process your request url as submitted</p>
          <p>Check to ensure the url precisely matches the link you received in your email</p>
          <p class="my-10"><span class="font-weight-bold">{{ fullUrl }}</span></p>
        </div>
      </v-sheet>
    </div>
    <div v-else-if="mode === 'resetPassword'">
      <UserAuthResetPassword
        :actionCode="oobCode"
        :continueUrl="continueUrl"
        v-model:isError="isError"
      />
    </div>
    <div v-else-if="mode === 'recoverEmail'">
      Set Recover Email Component Here
      <div>{{ oobCode }}</div>
      <div>{{ continueUrl }}</div>
    </div>
    <div v-else-if="mode === 'verifyEmail'">
      <UserAuthActionVerifyEmail
        :actionCode="oobCode"
        :continueUrl="continueUrl"
        v-model:isError="isError"
      />
    </div>
  </v-card>
</template>
