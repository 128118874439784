<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { formatMonthString } from '@/utils/dateFormatter'
import { getPaymentMethodString, getPaymentMethodIcon } from '@/utils/paymentMethod'
import ProgressOverlay from '@/components/ProgressOverlay'

const store = useStore()

const isLoading = ref(false)
const subscription = computed(() => store.state.subscription.subscription)
const error = computed(() => store.state.subscription.error)

const SubscriptionStatus = Object.freeze({
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  CANCELLED: 'cancelled',
  UNDEFINED: 'undefined'
});
const subscriptionStatus = computed(() => {
  if (!subscription.value) return SubscriptionStatus.UNDEFINED
  if (subscription.value.status === 'canceled') {
    const currentPeriodEndAt = new Date(subscription.value.currentPeriodEndAt)
    const now = new Date()
    if (currentPeriodEndAt > now) {
      return SubscriptionStatus.CANCELLED
    } else {
      return SubscriptionStatus.INACTIVE
    }
  }
  return SubscriptionStatus.ACTIVE
})
const nextActionString = computed(() => {
  let nextActionString = ""
  switch (subscriptionStatus.value) {
    case SubscriptionStatus.ACTIVE:
    nextActionString = 'Next payment'
      break;
    case SubscriptionStatus.CANCELLED:
      nextActionString = 'Canceled'
      break;
    case SubscriptionStatus.INACTIVE:
      nextActionString = 'Inactive'
      break;
  }
  return nextActionString

})
const nextDateString = computed(() => {
  let nextDateString = ""
  switch (subscriptionStatus.value) {
    case SubscriptionStatus.ACTIVE:
      nextDateString = `${formatMonthString(subscription.value.currentPeriodEndAt)}`
      break;
    case SubscriptionStatus.CANCELLED:
      nextDateString = `Your subscription ends on ${formatMonthString(subscription.value.currentPeriodEndAt)}`
      break;
    case SubscriptionStatus.INACTIVE:
      nextDateString = `Your subscription ended on ${formatMonthString(subscription.value.currentPeriodEndAt)}`
      break;
  }
  return nextDateString
})

const paymentMethodString = computed(() => {
  if (!subscription.value || !subscription.value.paymentMethod) return ''
  return getPaymentMethodString(subscription.value.paymentMethod)
})

const paymentMethodIcon = computed(() => {
  if (!subscription.value || !subscription.value.paymentMethod) return ''
  return getPaymentMethodIcon(subscription.value.paymentMethod)
})

const loadSubscription = async () => {
  isLoading.value = true
  store.dispatch('subscription/fetchSubscription')
    .catch((error) => console.error(error))
    .finally(() => isLoading.value = false)
}

onMounted(() => {
  loadSubscription()
})
</script>

<template>
  <v-card
    color="surface-light"
    class="mx-auto my-2 pa-6 text-body-1"
  >
    <ProgressOverlay
      :isLoading="isLoading"
    > 
      <div v-if="subscription">
        <p><span class="text-h6">{{ nextActionString }}</span></p>
        <p class="my-1 mb-2">{{ nextDateString }}</p>
        <p v-if="subscription.paymentMethod" class="my-2">
          <v-icon class="mr-3">{{ paymentMethodIcon }}</v-icon>
          <span class="code-font">{{ paymentMethodString }}</span>
        </p>
      </div>
      <div v-else>
        <p class="text-error">{{ error }}</p>
      </div>
    </ProgressOverlay>
  </v-card>
</template>

<style scoped>
.no-padding {
  padding: 0;
}
.code-font {
  font-family: monospace;
  font-weight: 550;
  font-size: 0.875em;
}
</style>
