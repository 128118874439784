<script setup>
import { ref, watch, onUnmounted, onMounted } from 'vue';
import { useStore } from 'vuex'
import { appBarStore } from '@/store/AppBarStore.js'
import { useFirestoreProfile } from '@/composables/useFirestoreProfile.js'
import { getFirestoreProfileSession } from '@/composables/useFirestoreProfileSession'
import UserSubscriptionOverview from '@/components/UserSubscriptionOverview.vue';

const store = useStore()

const isLoading = ref(true)
const profileData = ref([
  { label: 'Display Name', value: 'Temp' },
  { label: 'Joined', value: 'January 1, 2021'},
  { label: 'Available Turns', value: 0},
  { label: 'Turn Refresh Date', value: 'Unavailable'},
])
const index = {
  displayName: 0,
  joined: 1,
  availableTurns: 2,
  refreshDate: 3,
}

let profile, error, unsubscribe, stopWatch
if (store.state.user) {
  // eslint-disable-next-line no-unused-vars
  ({ profile, error, unsubscribe } = useFirestoreProfile(store.state.user))

  stopWatch = watch(profile, (newProfile) => {
    if (newProfile) {
      let createdAtDate = newProfile.createdAt.toDate(); 
      profileData.value[index.displayName].value = newProfile.displayName
      profileData.value[index.joined].value = createdAtDate.toLocaleDateString()
      isLoading.value = false
      unsubscribe()
    }
  }, { immediate: true });
}

onMounted(async () => {
  if (!store.state.user) return;

  const { session, error } = await getFirestoreProfileSession(store.state.user)
  if (session.value) {
    if (session.value.invocationBalance) {
      profileData.value[index.availableTurns].value = session.value.invocationBalance
    }
    if (session.value.invocationRefreshDate) {
      let refreshDate = session.value.invocationRefreshDate.toDate(); 
      profileData.value[index.refreshDate].value = refreshDate.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'America/New_York',
        timeZoneName: 'short'
      });
    }
  }
  if (error.value) {
    console.log(error.value)
  }
})

onUnmounted(() => {
  if (stopWatch) { stopWatch() }
  if (unsubscribe) { unsubscribe() }
})

appBarStore.value.setTitle('Account')

</script>

<template>
  <v-card
    max-width="800"
    class="mx-auto mt-4 text-start card-primary"
  >
    <v-card-title class="d-flex">
      <h2 class="text-h5">Account</h2>
    </v-card-title>

    <v-card-text>
      <p class="text-subtitle-1">Subscription</p>
      <UserSubscriptionOverview />
    </v-card-text> 
  </v-card>
</template>

<style scoped>

.list-title {
  color: #333333;
  font-weight: 500;
  }
</style>